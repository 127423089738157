import './App.css';
import './css/style.css';
import Sample from './page/Sample.js';

function App() {
  return (
    <div className="App">
      <Sample />
    </div>
  );
}

export default App;
