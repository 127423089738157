import React, { useEffect, useRef } from 'react';
import '../css/Sample.css';

function Sample() {
  return (
    <>
      <Header />
      <HeroBanner />
      {/* <ServiceSectionR /> */}
      {/* <Pick /> */}
      <PortfolioSectionR />
      <NOOK />
      <ServicesContent />
      <InquirySection />
      <Footer />
    </>
  )
}

const Header = () => {
  return (
    <header className="App-header">
      <div ><a href='/'><img src="http://krmyhome.com/wp-content/themes/myhome-theme/build/img/logo/myhomelogo.png" alt='logo' className="logo" /></a></div>
      <nav>
        <ul>
          <li><a href="#home">마이홈</a></li>
          <li><a href="#services">서비스</a></li>
          <li><a href="#DETAIL">안내</a></li>
          <li><a href="#contact">문의</a></li>
        </ul>
      </nav>
    </header>
  );
};

const HeroBanner = () => {
  return (
    <section className="hero-banner" id='home'>
      <img src="http://krmyhome.com/wp-content/themes/myhome-theme/build/img/main/a1.PNG" alt="마이홈" className="mainIMG" />
      <div className="hero-content">
        <p className='SubTitle'>집에 문제가 있으신가요?</p>
        <p className='SubTitle'>신축 인테리어 리모델링 집수리 전문업체</p>
        <h1 className='DetailTitle'>마이홈</h1>
        <img src="http://krmyhome.com/wp-content/themes/myhome-theme/build/img/logo/myhomelogo.png" alt='logo' className="logo" />
        {/* <a href="tel:0313751545" className='myhometel'><p className='SubTitle'>031 - 375 - 1545</p></a> */}
      </div>
    </section >
  );
};

// const ServiceSectionR = () => {
//   const serviceTextRef = useRef(null);
//   const serviceImagesRef = useRef(null);

//   useEffect(() => {
//     const observerOptions = {
//       threshold: 0.1,
//     };

//     const observerCallback = (entries, observer) => {
//       entries.forEach(entry => {
//         if (entry.isIntersecting) {
//           entry.target.classList.add('in-view');
//           observer.unobserve(entry.target);
//         }
//       });
//     };

//     const observer = new IntersectionObserver(observerCallback, observerOptions);

//     if (serviceTextRef.current) observer.observe(serviceTextRef.current);
//     if (serviceImagesRef.current) observer.observe(serviceImagesRef.current);

//     return () => {
//       if (serviceTextRef.current) observer.unobserve(serviceTextRef.current);
//       if (serviceImagesRef.current) observer.unobserve(serviceImagesRef.current);
//     };
//   }, []);

//   return (
//     <section className="service-sectionr">
//       <div className="nonBox"></div>
//       <div className="small-boxR" >
//         <div ref={serviceTextRef} className="service-text hidden" >
//           <h2 className='TitleDetail'>인테리어 리모델링 전문 업체</h2>
//           <p className="textBoxR">
//             <b className='emphasis'>인테리어 리모델링 전문업체로서,</b> 다양한 문제들을 신속하게 해결해드립니다.<br />
//             주방, 욕실, 거실 등 집안 곳곳의 인테리어와 설비를 점검하고, 필요한 수리를 진행합니다.<br />
//             타일 교체, 도배, 페인트 작업은 물론, 전기 및 배관 설비까지 꼼꼼하게 관리해 드립니다.<br />
//             작은 문제라도 사전에 발견하고 해결하여 쾌적하고 안전한 생활 공간을 유지할 수 있도록 도와드립니다.<br />
//             언제든지 연락 주시면, 신속하고 정확한 서비스로 여러분의 고민을 해결해드리겠습니다.
//           </p>
//         </div>
//       </div>
//       <div className="large-box">
//         <img src="http://krmyhome.com/wp-content/themes/myhome-theme/build/img/work/05.jpg" alt="Service" className="lage-img" />
//       </div>
//     </section>
//   );
// };


// const Pick = () => {
//   const PickData = [
//     {
//       imgSrc: "http://krmyhome.com/wp-content/themes/myhome-theme/build/img/logo/2.png",
//       altText: "icon",
//       description: "24시간 / 30분 이내 출동서비스"
//     },
//     {
//       imgSrc: "http://krmyhome.com/wp-content/themes/myhome-theme/build/img/logo/1.png",
//       altText: "icon",
//       description: "합리적인 비용"
//     },
//     {
//       imgSrc: "http://krmyhome.com/wp-content/themes/myhome-theme/build/img/logo/3.png",
//       altText: "icon",
//       description: "빠른 문제해결"
//     }
//   ]
//   return (
//     <section className="features" id="services">
//       {PickData.map((data, index) => (
//         <div key={index} className="feature-item">
//           <img src={data.imgSrc} alt={data.altText} />
//         </div>
//       ))}
//     </section>
//   );
// };




const PortfolioSectionR = () => {
  const serviceTextRef = useRef(null);
  const serviceImagesRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (serviceTextRef.current) observer.observe(serviceTextRef.current);
    if (serviceImagesRef.current) observer.observe(serviceImagesRef.current);

    return () => {
      if (serviceTextRef.current) observer.unobserve(serviceTextRef.current);
      if (serviceImagesRef.current) observer.unobserve(serviceImagesRef.current);
    };
  }, []);

  return (
    <section className="service-sectionr" >
      <div className="nonBox"></div>
      <div className="small-box">
        <div ref={serviceTextRef} className="service-text hidden" >
          <h2 className='TitleDetail'><span className="serviceIn">인테리어</span><span className="serviceRe">리모델링</span>전문 업체</h2>
          <p className="textBoxR">
            <b className='emphasis'>건축 전문 업체로서,</b> 다양한 문제들을 신속하게 해결해드립니다.<br />
            앞서가는 기술력과 노하우로 고객의 만족을 위해서 최선을 다하고 있습니다. <br />
            타일 시공 전문으로 서울 및 경기도 등 출장을 가고 있습니다. <br />
            확실한 사후관리와 고객 만족을 최우선으로 약속드립니다.
          </p>
        </div>
      </div>
      <div className="large-boxR">
        <img src="http://krmyhome.com/wp-content/themes/myhome-theme/build/img/main/a5.jpg" alt="Service" className="lage-img" />
      </div>
    </section>
  );
};
const NOOK = () => {
  return (
    <>
      <h2 className="NOOK1">거품은 NO</h2>
      <h2 className="NOOK2">품질은 OK</h2>
      <h2 className="NOOK3">고객만족도 100%</h2>
      <h2 className="NOOK4">하자시공 ZERO</h2>
    </>
  )
}


const ServicesContent = () => {
  const ServiceData = [
    {
      imgSrc: "http://krmyhome.com/wp-content/themes/myhome-theme/build/img/main/a2.jpg",
      altText: "Service Step 1",
      title: "STEP.01",
      description: "365일 무료 견적 서비스",
      subDescription: "당신의 집을 새롭게 변화시키는 전문가, 서울 및 경기도 대표 인테리어 업체!"
    },
    {
      imgSrc: "http://krmyhome.com/wp-content/themes/myhome-theme/build/img/main/a6.jpg",
      altText: "Service Step 2",
      title: "STEP.02",
      description: "현장진단 및 최저가격견적",
      subDescription: "시공전 현장 체크하여 진단합니다."
    },
    {
      imgSrc: "http://krmyhome.com/wp-content/themes/myhome-theme/build/img/main/a3.jpg",
      altText: "Service Step 3",
      title: "STEP.03",
      description: "전문장비를 통한 문제해결",
      subDescription: "전문가와 전문장비 등을 통해 문제를 해결합니다."
    },
    {
      imgSrc: "http://krmyhome.com/wp-content/themes/myhome-theme/build/img/main/a4.jpg",
      altText: "Service Step 4",
      title: "STEP.04",
      description: "문제해결 및 최종점검",
      subDescription: "빠르고 정확하게 그리고 깔끔하게 마이홈 차별화된 서비스."
    },
  ];

  const serviceTextRefs = useRef([]);
  const serviceImagesRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    serviceTextRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });
    serviceImagesRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => {
      serviceTextRefs.current.forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
      serviceImagesRefs.current.forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <section id="DETAIL" className="services">
      <p className="DetailSubTitleR">SERVICES DETAIL</p>
      <h2 className='DetailTitleR'>마이홈 서비스 내용</h2>
      <div className="service-container" >
        {ServiceData.map((data, index) => (
          <div
            ref={el => serviceImagesRefs.current[index] = el}
            className="service-images hidden"
            key={index}
          >
            <img src={data.imgSrc} alt={data.altText} className="service-image" />
            <div
              ref={el => serviceTextRefs.current[index] = el}
              id="service-text"
              className="service-text hidden"
            >
              <h2 className='TitleDetail' style={{ color: '#0098d1' }}>{data.title}</h2>
              <p>{data.description}<br />{data.subDescription}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};





const InquirySection = () => {
  return (
    <section id="contact" className="inquiry">
      <button className="kakao-button" onClick={openKakao}><img src='http://krmyhome.com/wp-content/themes/myhome-theme/build/img/logo/kakao.png' alt='카카오톡' className='Kakao' /></button>
    </section>
  );
};



function openKakao() {
  const openUrl = 'http://pf.kakao.com/_xmGxktG'
  window.open(openUrl, '_blank')
}


const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 마이홈. All rights reserved.</p>
      <div className="social-links">
        <p style={{ fontSize: '13px' }}>사업장 주소지 : 화성시 금곡로66 103호</p>
        <a href="tel:0313751545" ><p style={{ fontSize: '13px' }}>Tell : 031 - 375 - 1545</p></a>
        <p style={{ fontSize: '13px' }}>Mobile : 010 - 5961 - 1545</p>
      </div>
    </footer>
  );
};

export default Sample;
